import { http } from '@/core/api/';

const URL = 'api/parent/scholarship';

export const getAcademicYearAssistance = (studentSieId) =>
  http.get(`${URL}/assistanceyears/${studentSieId}`);

export const getPeriodsAssistance = (studentSieId, academicYearId) =>
  http.get(`${URL}/assistanceperiods/${studentSieId}/${academicYearId}`);

export const getServicesAssistance = (
  studentSieId,
  academicYearId,
  monthNumber
) =>
  http.get(
    `${URL}/assistanceservices/${studentSieId}/${academicYearId}/${monthNumber}`
  );

export const getScholarshipAssistance = (params, pageIndex, pageSize) =>
  http.get(`${URL}/assistance/${pageIndex}/${pageSize}`, { params });

export const getCertificationDocument = (assistanceSummaryId) =>
  http.get(`${URL}/visitscertificationdocument/${assistanceSummaryId}`);
