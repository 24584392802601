<template>
  <div class="result-row">
    <custom-callout class="rounded" :has-toggle="false">
      <div class="row mx-0 align-items-center">
        <div
          class="col-12 col-md-2 align-self-stretch d-flex align-items-center mb-2 mb-md-0 pl-md-0 border-right"
        >
          <span :class="`badge-status-${statusColor}`">
            {{ data.statusName }}
          </span>
        </div>
        <div
          class="col-12 col-md-8"
          :class="{ 'pr-md-0 mr-md-n4': data.hasSubmittedBill }"
        >
          <detail-generator class="mb-3" :details="details" />
          <div :key="`additional-payment-${index}`" v-for="(item, index) in data.additionalPayments" class="col-12 mt-2">
            <div class="row mx-auto bg-white rounded-pill p-2">
              <div class="d-flex align-items-center rounded-pill bg-light color-secondary-13 font-weight-bold px-2 py-1">
                <span class="d-flex align-items-center">
                  <em
                    class="text-orange fa fa-info-circle font-20"
                  />
                </span>
                <span class="d-flex align-items-center pl-2">
                  Detalle del pago adicional
                </span>
              </div>
              <div class="d-flex align-items-center text-gray mx-4">
                <span>Pago adicional:</span>
                <div class="d-flex justify-content-center align-items-center">
                  <span class="ml-3">
                    <em
                      class="text-green fa fa-usd-circle font-20 pt-1"
                    />
                  </span>
                  <span class="font-weight-bold text-green font-18 ml-1">{{ amount(item) }}</span>
                </div>
              </div>
              <div class="d-flex align-items-center border-left pl-4">
                <span class="text-gray">Fecha de pago:</span>
                <span class="text-black font-16 ml-3">
                  {{ item.paymentDate | date }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="data.hasSubmittedBill"
          class="col-12 col-md-2 text-right pl-md-0"
        >
          <action-button
            i="fas fa-print"
            text="Certificación de Visitas"
            variant="outline-success btn-block-xs-only"
            @click="downloadVisitCertifications"
          />
        </div>
        
      </div>
    </custom-callout>
  </div>
</template>

<script>
import Vue from 'vue';
import CustomCallout from 'custom-callout';
import DetailGenerator from '@/components/common/DetailGenerator.vue';
import filterName from '@/utils/constants/filterName';
import ActionButton from 'ActionButton';
import assistanceStatus from '@/utils/constants/scholarshipAssistanceStatus';
import statusColors from '@/utils/constants/statusColors';
import moment from 'moment';

export default {
  name: 'ResultRow',
  components: { CustomCallout, ActionButton, DetailGenerator },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    details() {
      const data = { ...this.data };
      const info = [
        {
          label: 'Servicio:',
          data: data.serviceName,
          class: 'col-12 col-md-4',
          dataClass: 'font-weight-bold',
        },
        {
          label: 'Periodo:',
          data: data.periodComputed,
          class: 'col-12 col-md-4',
        },
        {
          label: 'Asistencias:',
          data: data.assistanceCount,
          class: 'col-12 col-md-4',
        },
        {
          label: 'Fecha de Nómina:',
          data: data.payrollDate,
          class: 'col-12 col-md-4 mt-md-3',
          filters: [filterName.date],
        },
        {
          label: 'Fecha de Pago:',
          data: data.paymentDate,
          class: 'col-12 col-md-4 mt-md-3',
          filters: [filterName.date],
        },
        {
          label: 'Total:',
          data: data.totalAmount,
          class: `col-12 col-md-4 mt-md-3`,
          filters: [filterName.price],
        },
      ];
      return [{ info }];
    },
    statusColor() {
      switch (this.data.statusId) {
        case assistanceStatus.payment:
          return statusColors.GreenLight;
        case assistanceStatus.approved:
          return statusColors.Rose;
        case assistanceStatus.pending:
        case assistanceStatus.no_payment:
          return statusColors.Yellow;
        case assistanceStatus.denegate:
          return statusColors.Brown;
        case assistanceStatus.review:
          return statusColors.MediumGray;
        default:
          return null;
      }
    },
    amount() {
      return (item) => (isNaN(item?.additionalAmount) ? 0 : Number(item.additionalAmount).toFixed(2));
    },
  },
  methods: {
    downloadVisitCertifications() {
      this.$emit('certification-document', this.data.assistanceId);
    },
  },
};

Vue.filter('date', (value) => {
  if (value) {
    return moment(value)
      .locale('es-PR')
      .format('DD/MMM/YYYY');
  }
});

</script>

<style lang="scss" scoped>
.result-row {
  span[class^='badge-status-'] {
    padding: 0.5rem;
    min-width: 90%;
  }
}
@media only screen and (max-width: 768px) {
  .border-right {
    border-right: unset !important;
  }
  .border-left {
    border-left: unset !important;
  }
}
</style>
