<template>
  <div class="scholarship-attendance-history">
    <filter-manager
      ref="filterComponent"
      v-model="filters"
      class="mb-4"
      :display-advanced="false"
      :filters="filtersConfig"
      @academicYear="academicYearChangeHandler"
      @period="periodChangeHandler"
      @search="onSearchHandler"
    />
    <custom-separator class="my-4" text="Resultado de Asistencia de Beca" />
    <custom-result-not-found v-if="results.length == 0" />
    <template v-else>
      <result-row
        v-for="(row, idx) of results"
        :key="`result-${idx}`"
        class="mb-3"
        :data="row"
        @certification-document="downloadCertificationHandler"
      />
      <pagination
        v-if="pagination.totalItemCount > pagination.pageSize"
        :data="pagination"
        show-text
        @pagination-go-page="goToPage"
      />
    </template>
  </div>
</template>

<script>
import Pagination from 'pagination';
import FilterManager from 'FilterManager';
import CustomSeparator from 'custom-separator';
import CustomResultNotFound from 'custom-result-not-found';
import ResultRow from '@/views/students/scholarship-attendance/components/ResultRow.vue';
import {
  getAcademicYearAssistance,
  getPeriodsAssistance,
  getServicesAssistance,
  getScholarshipAssistance,
  getCertificationDocument,
} from '@/services/api/scholarship.api';
import { downloadBlobToFile } from '@/utils/blob';
import { getAssistanceApprovalStatus } from '@/api/code.api';

export default {
  name: 'ScholarshipAttendanceHistory',
  props: { studentSieId: { type: [String, Number], default: null } },
  components: {
    Pagination,
    FilterManager,
    CustomSeparator,
    CustomResultNotFound,
    ResultRow,
  },
  data: () => ({
    pagination: {
      currentPage: 1,
      totalItemCount: 0,
      pageCount: 1,
      count: 0,
      pageSize: 10,
    },
    filters: {},
    filterOptions: {
      academicYears: [],
      periods: [],
      services: [],
      status: [],
    },
    results: [],
  }),
  computed: {
    filtersConfig() {
      return [
        {
          name: 'academicYear',
          component: 'CustomDropDown',
          options: this.filterOptions.academicYears,
          placeholder: 'Seleccione Año Académico',
          fieldName: 'description',
          fieldKey: 'id',
          clearable: true,
          class: 'col-12 col-md-3',
          initialValue: this.filters.academicYear,
        },
        {
          name: 'period',
          component: 'CustomDropDown',
          options: this.filterOptions.periods,
          disabled: this.filterOptions.periods.length == 0,
          placeholder: 'Seleccione Periodo',
          fieldName: 'description',
          fieldKey: 'id',
          class: 'col-12 col-md-3',
          clearable: true,
        },
        {
          name: 'service',
          component: 'CustomDropDown',
          options: this.filterOptions.services,
          disabled: this.filterOptions.services.length == 0,
          placeholder: 'Seleccione Servicio',
          fieldName: 'name',
          fieldKey: 'id',
          class: 'col-12 col-md-3',
          clearable: true,
        },
        {
          name: 'status',
          component: 'CustomDropDown',
          options: this.filterOptions.status,
          disabled: this.filterOptions.status.length == 0,
          placeholder: 'Seleccione Estatus',
          fieldName: 'name',
          fieldKey: 'id',
          class: 'col-12 col-md-3',
          clearable: true,
        },
      ];
    },
  },
  async created() {
    await Promise.all([
      this.initFilters(),
      this.onSearchHandler(),
      this.getStatus(),
    ]);
  },
  methods: {
    async initFilters() {
      const { data } = await getAcademicYearAssistance(this.studentSieId);
      this.filterOptions.academicYears = data;
    },
    async goToPage(page) {
      this.pagination.currentPage = page;
      await this.onSearchHandler();
    },
    async onSearchHandler() {
      const model = { ...this.filters };
      const params = {
        studentSieId: this.studentSieId,
        academicYearId: model.academicYear?.id,
        periodId: model.period?.id,
        serviceId: model.service?.id,
        statusId: model.status?.id,
      };

      const { data: response } = await getScholarshipAssistance(
        params,
        this.pagination.currentPage,
        this.pagination.pageSize
      );

      this.results = [...response.data];
      this.pagination = {
        ...this.pagination,
        currentPage: response.currentPage,
        count: response.count,
        pageCount: response.pageCount,
        totalItemCount: response.totalItemCount,
      };
    },
    async downloadCertificationHandler(assistanceId) {
      await getCertificationDocument(assistanceId)
        .then(({ data }) => {
          downloadBlobToFile(data);
        })
        .catch((err) => {
          this.ShowToast(
            '',
            err.response?.data?.message || 'El documento no fue encontrado',
            'error'
          );
        });
    },
    async academicYearChangeHandler(academicYear) {
      this.filterOptions = {
        ...this.filterOptions,
        periods: [],
        services: [],
      };
      this.filters = {
        ...this.filters,
        period: null,
        service: null,
      };
      if (academicYear == null) return;
      this.filterOptions.periods = [];
      const model = this.filters;
      model.period = null;
      const { data } = await getPeriodsAssistance(
        this.studentSieId,
        academicYear.id
      );
      this.filterOptions.periods = data;
    },
    async periodChangeHandler(period) {
      this.filterOptions = {
        ...this.filterOptions,
        services: [],
      };
      this.filters = {
        ...this.filters,
        service: null,
      };
      if (period == null) return;
      this.filterOptions.services = [];
      const model = this.filters;
      model.service = null;
      const { data } = await getServicesAssistance(
        this.studentSieId,
        model.academicYear.id,
        period.id
      );
      this.filterOptions.services = data;
    },
    async getStatus() {
      await getAssistanceApprovalStatus().then(
        ({ data }) => (this.filterOptions.status = data)
      );
    },
  },
};
</script>
