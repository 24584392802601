<template>
  <div v-if="studentSieId">
    <page-header
      has-return
      :page="{
        icon: 'fas fa-car',
        title: 'Asistencia de Beca',
        routes: ['Inicio', 'Asistencia de Beca'],
      }"
    />
    <scholarship-attendance-history :student-sie-id="Number(studentSieId)" />
  </div>
</template>

<script>
import PageHeader from 'PageHeader';
import ScholarshipAttendanceHistory from '@/views/students/scholarship-attendance/components/ScholarshipAttendanceHistory.vue';

export default {
  name: 'ScholarshipAttendance',
  components: { PageHeader, ScholarshipAttendanceHistory },
  props: { studentSieId: { type: [String, Number], default: null } },
};
</script>
